import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { read, post, put, del } from 'shared/utils/request';

const initialState = {
  list: [],
};

const readAllAsync = createAsyncThunk('notice/readAll', async () => {
  const response = await read('/notice');
  return response;
});

const createNoticeAsync = createAsyncThunk('notice/create', async ({ noticeTitle, noticeContent }) => {
  const response = await post('/notice', {
    noticeTitle,
    noticeContent,
  });
  return response;
});

const updateNoticeAsync = createAsyncThunk('notice/update', async ({ id, noticeTitle, noticeContent }) => {
  const response = await put(`/notice/${id}`, { noticeTitle, noticeContent });
  return response;
});

const deleteNoticeAsync = createAsyncThunk('notice/delete', async (id) => {
  const response = await del(`/notice/${id}`);
  return response;
});

export const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(readAllAsync.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});

export const readAllNotices = readAllAsync;
export const createNotice = createNoticeAsync;
export const updateNotice = updateNoticeAsync;
export const deleteNotice = deleteNoticeAsync;

export default noticeSlice.reducer;
