import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'shared/i18n/locales/en-US.json';
import cn from 'shared/i18n/locales/zh-CN.json';

const resources = {
  en: {
    translation: en,
  },
  cn: {
    translation: cn,
  },
};

export const initI18n = (locale = 'cn') => {
  i18n.use(initReactI18next).init({
    resources,
    lng: locale,
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: (key) => {
      console.warn(`[i18n] ${key} not found.`);
      return key;
    },
  });
  return i18n;
};
