import _ from 'lodash';
import { isSSR } from './env';

export const getConfig = (property) => {
  if (property === null || property === undefined) {
    throw new Error('Calling getConfig with null or undefined argument');
  }
  if (isSSR()) {
    // eslint-disable-next-line global-require
    const config = require('config');
    return config.get(property);
  }
  // eslint-disable-next-line no-underscore-dangle
  const config = window.__CLIENT_CONFIG__ || {};
  const value = config[property];
  if (value === undefined) {
    throw new Error(`Configuration property "${property}" is not defined`);
  }
  return value;
};

export const getClientConfigScripts = (attributes) => {
  if (!_.isArray(attributes) || attributes.length < 1) {
    throw new Error('Attributes should not be empty');
  }
  // eslint-disable-next-line global-require
  const config = require('config');
  const attrObject = _.zipObject(
    attributes,
    attributes.map((attr) => config.get(attr))
  );
  return `window.__CLIENT_CONFIG__ = ${JSON.stringify(attrObject)}`;
};
