import UniversalRouter from 'universal-router';
import { isFunction, isArray } from 'lodash/fp';
import { getRole } from 'shared/selectors/account';
import { setFeature, setCurrentPage } from './slices/global';

import routes from './routes';

export default new UniversalRouter(routes, {
  async resolveRoute(context) {
    const {
      route,
      route: { preload, load, action, onEnter, feature, page, requireAuth, component, roles },
      store: { dispatch, getState },
      params,
    } = context;

    const state = getState();

    if (onEnter) {
      await onEnter(context, params);
    }

    if (feature) {
      dispatch(setFeature(feature));
    }

    if (page) {
      dispatch(setCurrentPage(page));
    }

    if (requireAuth) {
      if (!state.auth.isLogin) {
        return { redirect: '/' };
      }
      if (roles && !roles.includes(getRole(state))) {
        return { redirect: '/' };
      }
    }

    if (component) {
      return route;
    }

    let preloadData;
    if (isFunction(preload)) {
      preloadData = await preload(context);
    }

    if (isFunction(load)) {
      const module = await load({ preloadData });
      const result = await module.default(context);
      if (result.component) {
        return result;
      }
      if (isArray(result)) {
        route.children = result;
      }
    }

    if (isFunction(action)) {
      const result = await action({ ...context, preloadData });
      if (result.feature) {
        dispatch(setFeature(result.feature));
      }
      if (result.page) {
        dispatch(setCurrentPage(result.page));
      }
      return { ...context.route, ...result };
    }
    return context.next();
  },
});
