import { createSlice } from '@reduxjs/toolkit';
import { login, readProfile } from './auth';

const initialState = {
  entity: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(readProfile.fulfilled, (state, { payload }) => {
        state.entity = payload;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.entity = payload.account;
      });
  },
});

export default accountSlice.reducer;
