export const updateTag = (tagName, keyName, keyValue, attrName, attrValue) => {
  const node = document.head.querySelector(`${tagName}[${keyName}="${keyValue}"]`);
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    // eslint-disable-next-line no-unused-expressions
    node.parentNode?.removeChild(node);
  }
  const nextNode = document.createElement(tagName);
  nextNode.setAttribute(keyName, keyValue);
  nextNode.setAttribute(attrName, attrValue);
  document.head.appendChild(nextNode);
};

export const updateMeta = (name, content) => {
  updateTag('meta', 'name', name, 'content', content);
};

export const updateCustomMeta = (property, content) => {
  updateTag('meta', 'property', property, 'content', content);
};

export const updateLink = (rel, href) => {
  updateTag('link', 'rel', rel, 'href', href);
};

export const disableBodyScroll = () => {
  const { document } = global;
  document.body.style.overflow = 'hidden';
};

export const recoverBodyScroll = () => {
  const { document } = global;
  document.body.style.removeProperty('overflow');
};
