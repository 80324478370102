import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { post, read } from 'shared/utils/request';
import _ from 'lodash';
import moment from 'moment';

const initialState = {
  list: [],
  report: [],
};

const createAsync = createAsyncThunk(
  'application/create',
  async ({
    jobId,
    jobPostId,
    accountId,
    firstName,
    lastName,
    phoneNumber,
    phoneCountryCode,
    email,
    resumeUrl,
    resumeFileName,
    answers,
  }) => {
    const response = await post('/application', {
      jobId,
      jobPostId,
      accountId,
      firstName,
      lastName,
      phoneNumber,
      phoneCountryCode,
      email,
      resumeUrl,
      resumeFileName,
      answers: JSON.stringify(answers),
    });
    return response;
  }
);

const readAllAsync = createAsyncThunk('application/readAll', async ({ accountId }) => {
  const response = await read('/application/all', { accountId });
  return response;
});

const downloadReportAsync = createAsyncThunk('application/downloadReport', async (requisitionId) => {
  const response = await post(`/report/application?requisitionId=${requisitionId}`);
  return response;
});

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createAsync.fulfilled, (state, { payload }) => {
        state.list = [payload];
      })
      .addCase(readAllAsync.fulfilled, (state, { payload }) => {
        state.list = payload.content.map((application) => {
          const applicationInfo = _.cloneDeep(application);
          applicationInfo.createdAt = moment(application.createdAt).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
          return applicationInfo;
        });
      })
      .addCase(downloadReportAsync.fulfilled, (state, { payload }) => {
        state.report = payload;
      });
  },
});

export const create = createAsync;
export const readAll = readAllAsync;
export const downloadReport = downloadReportAsync;

export default applicationSlice.reducer;
