import React from 'react';
import { func, object } from 'prop-types';
import { Provider as ReactReduxProvider } from 'react-redux';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { DomainContext, MobileContext } from 'shared/contexts';
import moment from 'moment/moment';
import 'moment/locale/zh-cn';
import { I18nextProvider } from 'react-i18next';
import { initI18n } from 'shared/i18n';

moment.locale('zh-cn');

const App = ({ children, store, domainContext, mobileContext, insertCss }) => {
  const { locale } = store.getState().locale;

  return (
    <StyleContext.Provider value={{ insertCss }}>
      <ReactReduxProvider store={store}>
        <DomainContext.Provider value={domainContext}>
          <MobileContext.Provider value={mobileContext}>
            <I18nextProvider i18n={initI18n(locale)}>{children}</I18nextProvider>
          </MobileContext.Provider>
        </DomainContext.Provider>
      </ReactReduxProvider>
    </StyleContext.Provider>
  );
};

App.propTypes = {
  store: object.isRequired,
  domainContext: object.isRequired,
  mobileContext: object.isRequired,
  insertCss: func.isRequired,
};

export default App;
