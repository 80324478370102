import reducers from 'shared/slices';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

const { __INITIAL_STATE__ = {} } = global;
const isDev = process.env.NODE_ENV !== 'production';

const middlewares = isDev ? [createLogger({ collapsed: true })] : [];

export default configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  devTools: isDev,
  preloadedState: __INITIAL_STATE__,
});
