export const testIds = {
  header: {
    home: 'home_header',
    joinUs: 'join_us_header',
    superX: 'superX_header',
    training: 'training_header',
    notice: 'notice_header',
    faq: 'faq_header',
    aboutUs: 'about_us_header',
    loginIn: 'login_in',
  },
  home: {
    gradJob: 'grad_job',
    superXJob: 'superX_job',
    internJob: 'intern_job',
    noticeBody: 'notice_body',
    learnMore: 'learn_more',
    browse: 'browse',
  },
  form: {
    applyNow: 'apply_now',
    jobListReturn: 'job_list_return',
    applyJob: 'apply_job',
    firstName: 'first_name',
    lastName: 'last_name',
    gender: 'gender',
    phone: 'phone',
    email: 'email',
    attachment: 'attachment',
    upload_cancel: 'upload_attachment_cancel',
    upload: 'upload_attachment',
    education: 'education',
    schoolRegion: 'school_region',
    school: 'school',
    profession: 'profession',
    graduationTime: 'graduation_time',
    intendCity: 'intend_city',
    recruitSource: 'recruit_source',
    techLanguage: 'tech_language',
    referCode: 'refer_code',
    otherInfoChoose: 'other_info_choose',
    genderIdentity: 'gender_identity',
    help: 'help',
    helpMethod: 'help_method',
    privacyState: 'privacy_state',
    submitApplication: 'submit_application',
  },
  admin: {
    notice: {
      createNewNotice: 'create_new_notice',
      editNotice: 'edit_notice',
      cancelNoticeEditing: 'cancel_notice',
      confirmSubmitNotice: 'notice-submit-button',
    },
    report: {
      requisitionIdSelect: 'requisition_id_select',
      downloadReport: 'download_report',
    },
  },
};
