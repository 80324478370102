import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { read } from '../utils/request';
import { featureToggleType } from '../constants/featureToggleType';

const initialState = {
  feature: null,
  currentPage: null,
  loginModalVisible: false, // old vi version
  successPageVisible: false,
  inInternalTest: false,
  enableAccurateReferral: false,
  enableMultiLanguageSelect: false,
  isLoginModalVisible: false, // new vi version
};

const getAllFeatureTogglesAsync = createAsyncThunk('feature/all', async () => {
  const response = await read('/feature/all');
  return response;
});

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setFeature: (state, { payload }) => {
      state.feature = payload;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    toggleLoginModal: (state) => {
      state.loginModalVisible = !state.loginModalVisible;
    },
    toggleSuccessPage: (state) => {
      state.successPageVisible = !state.successPageVisible;
    },
    toggleWechatLoginModal: (state, { payload }) => {
      if (payload !== undefined) {
        state.isLoginModalVisible = payload;
        return;
      }
      state.isLoginModalVisible = !state.isLoginModalVisible;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFeatureTogglesAsync.fulfilled, (state, action) => {
      action.payload.forEach((featureToggle) => {
        if (featureToggle.featureType === featureToggleType.IN_INTERNAL_TEST) {
          state.inInternalTest = featureToggle.enabled;
        } else if (featureToggle.featureType === featureToggleType.ENABLE_ACCURATE_REFERRAL) {
          state.enableAccurateReferral = featureToggle.enabled;
        } else if (featureToggle.featureType === featureToggleType.ENABLE_MULTI_LANGUAGE_SELECT) {
          state.enableMultiLanguageSelect = featureToggle.enabled;
        }
      });
      state.featureToggles = action.payload;
    });
  },
});

export const { setFeature, setCurrentPage, toggleLoginModal, toggleSuccessPage, toggleWechatLoginModal } = globalSlice.actions;
export const getAllFeatureToggles = getAllFeatureTogglesAsync;

export default globalSlice.reducer;
