import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { read } from 'shared/utils/request';
import { DEFAULT_PAGE, DEFAULT_SIZE } from 'shared/constants/pagination';

const initialState = {
  list: [],
};

const readAllAsync = createAsyncThunk('job/readAll', async ({ page = DEFAULT_PAGE, size = DEFAULT_SIZE }) => {
  const response = await read('/job/all', { page, size });
  return response;
});

const readJobAsync = createAsyncThunk('job/readJob', async ({ jobId }) => {
  const response = await read(`/job/${jobId}`);
  return response;
});

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(readAllAsync.fulfilled, (state, action) => {
        state.list = action.payload.content;
      })
      .addCase(readJobAsync.fulfilled, (state, { payload }) => {
        const findPayloadIndex = state.list.findIndex((job) => job.id === payload.id);
        state.list[findPayloadIndex] = payload;
      });
  },
});

export const readAll = readAllAsync;
export const readJob = readJobAsync;

export default jobSlice.reducer;
