import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './auth';
import globalReducer from './global';
import accountReducer from './account';
import noticeReducer from './notice';
import jobReducer from './job';
import applicationReducer from './application';
import localeReducer from './locale';

const reducers = {
  global: globalReducer,
  account: accountReducer,
  auth: authReducer,
  notice: noticeReducer,
  application: applicationReducer,
  job: jobReducer,
  form: formReducer,
  locale: localeReducer,
};

export default combineReducers(reducers);
