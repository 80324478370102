export const getCurrentAccount = (state) => {
  const account = state.account.entity;
  const { currentAccountId } = state.auth;
  if (!account || account.id !== currentAccountId) {
    return null;
  }
  const { firstName, lastName, nickname } = account;
  return {
    name: firstName || lastName ? `${lastName}${firstName}` : nickname,
    ...account,
  };
};

export const getRole = (state) => {
  const currentAccount = getCurrentAccount(state);
  return currentAccount ? currentAccount.roleKey : null;
};
