import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import cookie from 'js-cookie';
import { read, post } from 'shared/utils/request';
import { AUTHORIZATION } from 'shared/constants/cookies';

const initialState = {
  authorization: null,
  isLogin: false,
  currentAccountId: null,
};

const loginAsync = createAsyncThunk('auth/login', async ({ username, password, captcha }) => {
  const response = await post(
    '/auth/local',
    { username, password, captcha, ignoreAuthRedirection: true },
    {
      headers: {
        'x-auth-username': username,
        'x-auth-password': password,
      },
    }
  );
  return response;
});

const wechatLoginAsync = createAsyncThunk('auth/wechatLogin', async ({ code, source }) => {
  const response = await post(
    '/auth/wechat',
    { ignoreAuthRedirection: true },
    {
      headers: {
        'x-auth-wechat-code': code,
        'x-auth-wechat-source': source,
      },
    }
  );
  return response;
});

const logoutAsync = createAsyncThunk('auth/logout', async () => {
  const response = await post('/auth/logout');
  return response;
});

const readProfileAsync = createAsyncThunk('auth/readProfile', async () => {
  const response = await read('/account/mine');
  return response;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthorization: (state, { payload }) => {
      state.authorization = payload;
    },
    removeAuthorization: (state) => {
      state.authorization = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.fulfilled, (state, { payload: { account, authorization } }) => {
        state.isLogin = true;
        state.authorization = authorization;
        state.currentAccountId = account.Id;
        cookie.set(AUTHORIZATION, authorization, { expires: 60 });
      })
      .addCase(wechatLoginAsync.fulfilled, (state, { payload: { account, authorization } }) => {
        state.isLogin = true;
        state.authorization = authorization;
        state.currentAccountId = account.Id;
        cookie.set(AUTHORIZATION, authorization, { expires: 60 });
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.isLogin = false;
        state.authorization = null;
        state.currentAccountId = null;
        cookie.remove(AUTHORIZATION);
      })
      .addCase(readProfileAsync.fulfilled, (state, { payload: { id } }) => {
        state.isLogin = true;
        state.currentAccountId = id;
      })
      .addCase(readProfileAsync.rejected, (state) => {
        state.isLogin = false;
        state.authorization = null;
        state.currentAccountId = null;
        cookie.remove(AUTHORIZATION);
      });
  },
});

export const { setAuthorization, removeAuthorization } = authSlice.actions;
export const login = loginAsync;
export const wechatLogin = wechatLoginAsync;
export const logout = logoutAsync;
export const readProfile = readProfileAsync;

export default authSlice.reducer;
