import { testIds } from 'shared/constants/test-ids';
import { matomoEventTypeMap } from 'shared/utils/matomoConfig';

export const wrapLink = (link) => (link ? `/${link}` : `/`);

export const pageKey = {
  HOME: 'HOME',
  RECRUITMENT: 'RECRUITMENT',
  SUPER_X: 'SUPER_X',
  TRAINING: 'TRAINING',
  NOTICE: 'NOTICE',
  FAQ: 'FAQ',
  ABOUT: 'ABOUT',
  ACCOUNT: 'ACCOUNT',
  ADMIN: 'ADMIN',
  ADMIN_UPLOAD_RESUME: 'ADMIN_UPLOAD_RESUME',
  ADMIN_DOWNLOAD_REPORT: 'ADMIN_DOWNLOAD_REPORT',
  ADMIN_NOTICE: 'ADMIN_NOTICE',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
};

export const loginPage = {
  key: pageKey.LOGIN,
  textKey: 'page.login',
  link: null,
};

export const logoutPage = {
  key: pageKey.LOGOUT,
  textKey: 'page.logout',
  link: null,
  matomoEventType: matomoEventTypeMap.nav.logout,
};

export const profilePage = {
  key: pageKey.ACCOUNT,
  textKey: 'page.account',
  link: wrapLink('account'),
  matomoEventType: matomoEventTypeMap.nav.myCenter,
};

export const managementPage = {
  key: pageKey.ADMIN,
  textKey: 'page.management',
  link: wrapLink('admin/upload-resume'),
  matomoEventType: matomoEventTypeMap.nav.admin,
};

export const commonPages = [
  {
    key: pageKey.HOME,
    textKey: 'page.home',
    link: '',
    testId: testIds.header.home,
    matomoEventType: matomoEventTypeMap.nav.home,
  },
  {
    key: pageKey.RECRUITMENT,
    textKey: 'page.joinUs',
    link: 'recruitment',
    testId: testIds.header.joinUs,
    matomoEventType: matomoEventTypeMap.nav.joinUs,
  },
  {
    key: pageKey.SUPER_X,
    textKey: 'page.superX',
    link: 'super-x',
    testId: testIds.header.superX,
    matomoEventType: matomoEventTypeMap.nav.superX,
  },
  {
    key: pageKey.TRAINING,
    textKey: 'page.training',
    link: 'training',
    testId: testIds.header.training,
    matomoEventType: matomoEventTypeMap.nav.training,
  },
  {
    key: pageKey.NOTICE,
    textKey: 'page.notice',
    link: 'notice',
    testId: testIds.header.notice,
    matomoEventType: matomoEventTypeMap.nav.notice,
  },
  {
    key: pageKey.FAQ,
    textKey: 'page.faq',
    link: 'faq',
    testId: testIds.header.faq,
    matomoEventType: matomoEventTypeMap.nav.faq,
  },
  {
    key: pageKey.ABOUT,
    textKey: 'page.about',
    link: 'about',
    testId: testIds.header.aboutUs,
    matomoEventType: matomoEventTypeMap.nav.aboutUs,
  },
].map((item) => ({ ...item, link: wrapLink(item.link) }));
