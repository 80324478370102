import axios from 'axios';
import cookie from 'js-cookie';
import { AUTHORIZATION } from '../constants/cookies';
import { isSSR } from './env';
import { getConfig } from './config-helper';

// todo update after pulumi iac
const apiDomain = getConfig('serverAPIDomain');

const request = axios.create({
  baseURL: apiDomain,
});
axios.defaults.withCredentials = true;
if (cookie.get(AUTHORIZATION)) {
  request.defaults.headers.common['x-auth-token'] = cookie.get(AUTHORIZATION);
}
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const errorResponse = error?.response;
    if (errorResponse?.status) {
      const ignoreAuthRedirection = !!JSON.parse(errorResponse.config.data || '{}')?.ignoreAuthRedirection;
      const onUnauthorized = errorResponse?.status === 401 || errorResponse?.status === 403;

      if (!isSSR()) {
        if (onUnauthorized && !ignoreAuthRedirection) {
          // eslint-disable-next-line no-alert
          alert('权限错误，请重新登录适当账号');
        } else if (errorResponse.data instanceof Array) {
          // eslint-disable-next-line no-alert
          alert(errorResponse.data[0].message);
        } else {
          // eslint-disable-next-line no-alert
          alert(errorResponse.data?.message || errorResponse?.data?.error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const read = async (url, queryParams = {}) => {
  const response = await request.get(url, {
    params: queryParams,
  });
  return response.data;
};

export const post = async (url, data, options) => {
  const response = await request.post(url, data, options);
  return response.data;
};

export const put = async (url, body, options) => {
  const response = await request.put(url, body, options);
  return response.data;
};

export const del = async (url) => {
  const response = await request.delete(url);
  return response.data;
};

export default request;
