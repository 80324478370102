import { roles } from 'shared/constants/role';
import { wrapLink } from 'shared/constants/pages';

export default [
  {
    path: wrapLink(''),
    chunks: ['home'],
    children: true,
    load: () => import(/* webpackChunkName: 'home' */ 'modules/home/routes'),
  },
  {
    path: wrapLink('recruitment'),
    chunks: ['recruitment'],
    children: true,
    load: () => import(/* webpackChunkName: 'recruitment' */ 'modules/recruitment/routes'),
  },
  {
    path: wrapLink('super-x'),
    chunks: ['super-x'],
    children: true,
    load: () => import(/* webpackChunkName: 'super-x' */ 'modules/super-x/routes'),
  },
  {
    path: wrapLink('account'),
    chunks: ['account'],
    children: true,
    requireAuth: true,
    load: () => import(/* webpackChunkName: 'account' */ 'modules/account/routes'),
  },
  {
    path: wrapLink('faq'),
    chunks: ['faq'],
    children: true,
    load: () => import(/* webpackChunkName: 'faq' */ 'modules/faq/routes'),
  },
  {
    path: wrapLink('about'),
    chunks: ['about'],
    children: true,
    load: () => import(/* webpackChunkName: 'about' */ 'modules/about/routes'),
  },
  {
    path: wrapLink('training'),
    chunks: ['training'],
    children: true,
    load: () => import(/* webpackChunkName: 'training' */ 'modules/training/routes'),
  },
  {
    path: wrapLink('notice'),
    chunks: ['notice'],
    children: true,
    load: () => import(/* webpackChunkName: 'notice' */ 'modules/notice/routes'),
  },
  {
    path: wrapLink('admin'),
    chunks: ['admin'],
    children: true,
    requireAuth: true,
    roles: [roles.ADMIN],
    load: () => import(/* webpackChunkName: 'admin' */ 'modules/admin/routes'),
  },
  {
    path: wrapLink('(.*)'),
    chunks: ['not-found'],
    featureGroup: 'NOT_FOUND',
    load: () => import(/* webpackChunkName: 'not-found' */ 'modules/not-found/routes'),
  },
];
